import { createIcon } from "@chakra-ui/icon";

import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Switch,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorMode,
  useRadio,
  VStack,
} from "@chakra-ui/react";
import { ResponsiveBar } from "@nivo/bar";
import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import React, { Component, useEffect, useState, useMemo } from "react";
import ReactDatePicker from "react-datepicker";
import { AiOutlineClose } from "react-icons/ai";
import { FaCross } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { connect } from "react-redux";
import Select, { components } from "react-select";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules
import { uuid } from "uuidv4";
import Countdown from "../custom_node_modules/react-countdown";
import { Spinner } from "../platform/components";
import TradeList from "../platform/dashboard/DynamicDashboard/TradeList";
import AccountBalance from "../platform/dashboard/panels/AccountBalance";
import apiCalls from "../platform/helpers/apiCalls";
import { APIEndpoint } from "../platform/helpers/dataStructures";
import { calculateCommission } from "../platform/helpers/tradeCalculations";
import TradeDetailModal from "../platform/trades/TradeDetailModal";
import "./datepicker.css";
import { ReactComponent as HomeIcon } from "./images/glass/Home.svg";
import { ReactComponent as HomeOffIcon } from "./images/glass/HomeOff.svg";
import { ReactComponent as RoadmapIcon } from "./images/glass/Roadmap.svg";
import { ReactComponent as RoadmapOffIcon } from "./images/glass/RoadmapOff.svg";
import { ReactComponent as TradesIcon } from "./images/glass/Trades.svg";
import { ReactComponent as TradesOffIcon } from "./images/glass/TradesOff.svg";
import { ReactComponent as UploadIcon } from "./images/glass/Upload.svg";
import { ReactComponent as UploadOffIcon } from "./images/glass/UploadOff.svg";

import Creatable, { useCreatable } from "react-select/creatable";

import styles from "./index.module.css";
import "./style.css";

var currencyFormatter = require("currency-formatter");

// Buttons

export const PrimaryButton = (props) => {
  let overrideStyleString = `${styles.button} ${styles.primaryButton}`;

  if (props.fat === true) {
    overrideStyleString = overrideStyleString + ` ${styles.fat}`;
  }

  return (
    <Button {...props} className={overrideStyleString}>
      {props.children}
    </Button>
  );
};

export const SecondaryButton = (props) => {
  let overrideStyleString = `${styles.button} ${styles.secondaryButton}`;

  if (props.fat === true) {
    overrideStyleString = overrideStyleString + ` ${styles.customSecondary}`;
  }

  return (
    <Button {...props} className={overrideStyleString}>
      {props.children}
    </Button>
  );
};

export const NegativeButton = (props) => {
  let overrideStyleString = `${styles.button} ${styles.negativeButton}`;

  if (props.dark === true) {
    overrideStyleString = overrideStyleString + ` ${styles.dark}`;
  }

  return (
    <Button {...props} className={overrideStyleString}>
      {props.children}
    </Button>
  );
};

export const OutlineButton = (props) => {
  if (props.active === true) {
    return (
      <Button {...props} className={styles.outlineButtonActive}>
        {props.children}
      </Button>
    );
  } else {
    return (
      <Button {...props} className={styles.outlineButton}>
        {props.children}
      </Button>
    );
  }
};

export const CustomIconButton = (props) => {
  // const [activated, setActivated] = useState(props.activated);

  let override = "";
  let overrideActive = "";
  let extra = "";
  if (props.overrideIconColor === true) {
    override = styles.overRideIconColor;
    overrideActive = styles.overRideIconColorActive;
  }

  if (props.redBackground === true) {
    extra = styles.redBackgroundSVG;
  }

  if (props.activated === true) {
    return (
      <Button
        bg={props.bg}
        rightIcon={props.icon}
        className={`${styles.button} ${styles.iconButton} ${styles.activated} ${overrideActive} ${extra}`}
        {...props}
      >
        {props.children}
      </Button>
    );
  } else {
    return (
      <Button
        bg={props.bg}
        rightIcon={props.icon}
        className={`${styles.button} ${styles.iconButton}  ${styles.deactivated} ${override} ${extra}`}
        {...props}
      >
        {props.children}
      </Button>
    );
  }
};

export const NotificationButton = (props) => {
  const [activated, setActivated] = useState(props.activated);
  if (activated === true) {
    return (
      <IconButton
        onClick={() => {
          setActivated(!activated);
        }}
        icon={<BellNotified />}
        {...props}
        className={`${styles.roundButton} ${styles.bellActivated}`}
      ></IconButton>
    );
  } else {
    return (
      <IconButton
        onClick={() => {
          setActivated(!activated);
        }}
        icon={<Bell />}
        {...props}
        className={`${styles.roundButton}  ${styles.bellDeactivated}`}
      ></IconButton>
    );
  }
};

export const RoundIcon = (props) => {
  if (props.active === true) {
    return (
      <IconButton
        isActive={props.active}
        icon={props.icon}
        {...props}
        className={`${styles.roundButton} ${styles.roundIcon} ${styles.activeRoundIcon}`}
      ></IconButton>
    );
  } else {
    return (
      <IconButton
        isActive={props.active}
        icon={props.icon}
        {...props}
        className={`${styles.roundButton} ${styles.roundIcon}`}
      ></IconButton>
    );
  }
};

// Icons

export const FilterIcon = createIcon({
  path: <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" fill="transparent" />,
  displayName: "FilterIcon",
});
export const MoreIcon = createIcon({
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        stroke="#5F606D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
        stroke="#5F606D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
        stroke="#5F606D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  displayName: "MoreIcon",
});

export const BellIcon = createIcon({
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.44752 7.96942C6.76186 5.14032 9.15317 3 11.9997 3C14.8462 3 17.2375 5.14032 17.5518 7.96942L17.8036 10.2356C17.8068 10.2645 17.8085 10.279 17.8101 10.2933C17.939 11.417 18.3048 12.5005 18.8832 13.4725C18.8906 13.4849 18.8981 13.4973 18.913 13.5222L19.4911 14.4856C20.0156 15.3599 20.2779 15.797 20.2213 16.1559C20.1836 16.3946 20.0607 16.6117 19.8753 16.7668C19.5968 17 19.087 17 18.0674 17H5.93191C4.91236 17 4.40259 17 4.12401 16.7668C3.93865 16.6117 3.81576 16.3946 3.77809 16.1559C3.72147 15.797 3.98374 15.3599 4.5083 14.4856L5.08633 13.5222C5.10129 13.4973 5.10876 13.4849 5.11612 13.4725C5.69456 12.5005 6.06032 11.417 6.18926 10.2933C6.1909 10.279 6.19251 10.2645 6.19572 10.2356L6.44752 7.96942Z"
        fill="#5F606D"
      />
      <path
        d="M8.99968 17C8.99968 17.394 9.07727 17.7841 9.22804 18.1481C9.3788 18.512 9.59978 18.8427 9.87836 19.1213C10.1569 19.3999 10.4876 19.6209 10.8516 19.7716C11.2156 19.9224 11.6057 20 11.9997 20C12.3936 20 12.7838 19.9224 13.1477 19.7716C13.5117 19.6209 13.8424 19.3999 14.121 19.1213C14.3996 18.8427 14.6206 18.512 14.7713 18.1481C14.9221 17.7841 14.9997 17.394 14.9997 17H8.99968Z"
        fill="#5F606D"
      />
    </svg>
  ),
  displayName: "BellIcon",
});

export const WarningIcon = createIcon({
  path: (
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 13C11.4477 13 11 12.5523 11 12L11 8C11 7.44771 11.4477 7 12 7C12.5523 7 13 7.44771 13 8L13 12C13 12.5523 12.5523 13 12 13ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15L11.99 15C11.4377 15 10.99 15.4477 10.99 16C10.99 16.5523 11.4377 17 11.99 17L12 17Z"
      fill="var(--graph-orange-bright)"
    />
  ),
  displayName: "WarningIcon",
});

export const EditIcon = createIcon({
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.03589 18C7.1685 18 7.29568 17.9473 7.38945 17.8536L18.678 6.565C18.8655 6.37747 18.9708 6.12316 18.9708 5.858C18.9708 5.59284 18.8655 5.33853 18.678 5.151L15.849 2.322C15.6615 2.13453 15.4072 2.02921 15.142 2.02921C14.8768 2.02921 14.6225 2.13453 14.435 2.322L3.14645 13.6106C3.05268 13.7043 3 13.8315 3 13.9641V17.5C3 17.7761 3.22386 18 3.5 18H7.03589Z"
        fill="#5F606D"
      />
      <path
        d="M4 20H20C20.5523 20 21 20.4477 21 21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21C3 20.4477 3.44772 20 4 20Z"
        fill="#5F606D"
      />
    </svg>
  ),
  displayName: "EditIcon",
});

export const DeleteIcon = createIcon({
  path: (
    <path d="M6.85847 5V1.4C6.85847 1.08174 6.9849 0.776516 7.20994 0.551472C7.43498 0.326428 7.74021 0.2 8.05847 0.2H17.6585C17.9767 0.2 18.282 0.326428 18.507 0.551472C18.732 0.776516 18.8585 1.08174 18.8585 1.4V5H23.6585C24.3212 5 25 5.53726 25 6.2C25 6.86274 24.3212 7.4 23.6585 7.4H22.4585V23C22.4585 23.3183 22.332 23.6235 22.107 23.8485C21.882 24.0736 21.5767 24.2 21.2585 24.2H4.5C4.18174 24.2 3.83498 24.0736 3.60994 23.8485C3.3849 23.6235 3.25847 23.3183 3.25847 23V7.4H2.05847C1.39573 7.4 0.858468 6.86274 0.858468 6.2C0.858468 5.53726 1.39573 5 2.05847 5H6.85847ZM9.25847 2.6V5H16.4585V2.6H9.25847Z" />
  ),
  displayName: "DeleteIcon",
});

export const ShareIcon = createIcon({
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path d="M13 15C13 14.4477 12.5523 14 12 14H11C9.3596 13.9994 7.75023 14.4471 6.34587 15.2949C5.17356 16.0026 4.18133 16.9665 3.44125 18.1103C3.31345 18.3078 2.99988 18.2353 3 18C3 12.814 6.94728 8.55028 12.0014 8.04923C12.551 7.99475 13 7.55228 13 7V5.08062C13 4.24212 13.9699 3.77595 14.6247 4.29976L22.0239 10.2191C22.5243 10.6195 22.5243 11.3805 22.0239 11.7809L14.6247 17.7002C13.9699 18.2241 13 17.7579 13 16.9194V15Z" />
    </svg>
  ),
  displayName: "ShareIcon",
});

export const ExchangeIcon = createIcon({
  path: (
    <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 9H8V11H17L12 6V9ZM7 13L12 18V15H16V13H7Z" />
  ),
  displayName: "ExchangeIcon",
});

export const SuitcaseIcon = createIcon({
  path: (
    <path d="M15 3C15.552 3 16 3.448 16 4V6H21C21.552 6 22 6.448 22 7V20C22 20.552 21.552 21 21 21H3C2.448 21 2 20.552 2 20V7C2 6.448 2.448 6 3 6H8V4C8 3.448 8.448 3 9 3H15ZM8 8H6V19H8V8ZM18 8H16V19H18V8ZM14 5H10V6H14V5Z" />
  ),
  displayName: "SuitcaseIcon",
});
export const PinIcon = createIcon({
  path: (
    <path d="M11 17.938C8.98271 17.6813 7.13885 16.6661 5.84319 15.0988C4.54752 13.5314 3.89728 11.5295 4.02462 9.49997C4.15196 7.47041 5.04733 5.56549 6.52874 4.17238C8.01016 2.77927 9.96645 2.00252 12 2C14.036 1.99849 15.9961 2.77334 17.4807 4.16668C18.9653 5.56002 19.8628 7.467 19.9903 9.49904C20.1178 11.5311 19.4657 13.5353 18.1669 15.1033C16.868 16.6712 15.0203 17.685 13 17.938V20.012C16.946 20.104 20 20.735 20 21.5C20 22.328 16.418 23 12 23C7.582 23 4 22.328 4 21.5C4 20.735 7.054 20.104 11 20.012V17.938ZM12 12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 10C14 9.46957 13.7893 8.96086 13.4142 8.58579C13.0391 8.21072 12.5304 8 12 8C11.4696 8 10.9609 8.21072 10.5858 8.58579C10.2107 8.96086 10 9.46957 10 10C10 10.5304 10.2107 11.0391 10.5858 11.4142C10.9609 11.7893 11.4696 12 12 12Z" />
  ),
  displayName: "PinIcon",
});

export const NoteIcon = createIcon({
  path: (
    <path d="M10.7676 9.91667L10.6847 9.92162C10.5266 9.94027 10.3794 10.0116 10.2668 10.1242C10.1542 10.2368 10.0828 10.384 10.0642 10.5421L10.0592 10.625V14.875H2.97449C2.78751 14.8754 2.60802 14.8015 2.47547 14.6696C2.34291 14.5377 2.26814 14.3586 2.26758 14.1716V2.82837C2.26758 2.44021 2.58279 2.125 2.97095 2.125H14.3142C14.7024 2.125 15.0176 2.44163 15.0176 2.83263V9.91667H10.7676ZM15.0176 11.3333L11.4759 14.8729V11.3333H15.0176Z" />
  ),
  displayName: "NoteIcon",
});

export const CollapseIcon = createIcon({
  path: (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_502_12853)">
        <path
          d="M16.0919 6.15074L19.6066 2.63602L21.364 4.39338L17.8493 7.9081L20.4853 10.5441H13.4559V3.5147L16.0919 6.15074Z"
          fill="#5F606D"
        />
        <path
          d="M7.90808 17.8493L4.39337 21.364L2.63601 19.6066L6.15073 16.0919L3.51469 13.4559H10.5441V20.4853L7.90808 17.8493Z"
          fill="#5F606D"
        />
      </g>
    </svg>
  ),
  displayName: "CollapseIcon",
});

export const AddWidgetStroke = createIcon({
  path: (
    <svg>
      <path
        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
        fill="transparent"
      />
      <path d="M12 8V16" />
      <path d="M8 12H16" />
    </svg>
  ),
  displayName: "AddWidgetStroke",
});

export const ChevronDown = createIcon({
  path: <path d="M6 9L12 15L18 9" stroke="var(--light-gray)" />,
  displayName: "ChevronDown",
});

export const FileUpload = createIcon({
  path: (
    <path
      d="M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM13 12H16L12 8L8 12H11V16H13V12Z"
      stroke="var(--light-gray)"
      fill="transparent"
    />
  ),
  displayName: "FileUpload",
});

export const ChevronLeft = createIcon({
  path: (
    <path
      d="M12.5 15L7.5 10L12.5 5"
      stroke="var(--light-gray)"
      fill="transparent"
    />
  ),
  displayName: "ChevronLeft",
});

export const ChevronRight = createIcon({
  path: (
    <path d="M6 12L10 8L6 4" stroke="var(--light-gray)" fill="transparent" />
  ),
  displayName: "ChevronRight",
});

export const CloseIcon = createIcon({
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 5L5 15"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 5L15 15"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  displayName: "CloseIcon",
});

export const ChevronUp = createIcon({
  path: <path d="M18 15L12 9L6 15" stroke="var(--light-gray)" />,
  displayName: "ChevronUp",
});

export const BellNotified = createIcon({
  path: (
    <svg>
      <path d="M13.7942 3.29494C13.2297 3.10345 12.6259 3 12 3C9.15354 3 6.76223 5.14032 6.44789 7.96942L6.19608 10.2356L6.18963 10.2933C6.06068 11.417 5.69492 12.5005 5.11649 13.4725L5.0867 13.5222L4.50866 14.4856C3.98411 15.3599 3.72183 15.797 3.77845 16.1559C3.81613 16.3946 3.93902 16.6117 4.12438 16.7668C4.40295 17 4.91273 17 5.93227 17H18.0678C19.0874 17 19.5971 17 19.8757 16.7668C20.0611 16.6117 20.184 16.3946 20.2216 16.1559C20.2783 15.797 20.016 15.3599 19.4914 14.4856L18.9134 13.5222L18.8836 13.4725C18.4274 12.7059 18.1034 11.8698 17.9237 10.9994C15.1975 10.9586 13 8.73592 13 6C13 5.00331 13.2917 4.07473 13.7942 3.29494ZM16.2741 4.98883C16.0999 5.28551 16 5.63109 16 6C16 6.94979 16.6621 7.74494 17.5499 7.94914C17.4205 6.82135 16.9609 5.80382 16.2741 4.98883Z" />
      <path d="M9 17C9 17.394 9.0776 17.7841 9.22836 18.1481C9.37913 18.512 9.6001 18.8427 9.87868 19.1213C10.1573 19.3999 10.488 19.6209 10.8519 19.7716C11.2159 19.9224 11.606 20 12 20C12.394 20 12.7841 19.9224 13.1481 19.7716C13.512 19.6209 13.8427 19.3999 14.1213 19.1213C14.3999 18.8427 14.6209 18.512 14.7716 18.1481C14.9224 17.7841 15 17.394 15 17L12 17H9Z" />
      <circle cx="18" cy="6" r="2.5" fill="#2595FD" stroke="#2595FD" />
    </svg>
  ),
  displayName: "BellNotified",
});

export const Bell = createIcon({
  path: (
    <svg>
      <path d="M13.7942 3.29494C13.2297 3.10345 12.6259 3 12 3C9.15354 3 6.76223 5.14032 6.44789 7.96942L6.19608 10.2356L6.18963 10.2933C6.06068 11.417 5.69492 12.5005 5.11649 13.4725L5.0867 13.5222L4.50866 14.4856C3.98411 15.3599 3.72183 15.797 3.77845 16.1559C3.81613 16.3946 3.93902 16.6117 4.12438 16.7668C4.40295 17 4.91273 17 5.93227 17H18.0678C19.0874 17 19.5971 17 19.8757 16.7668C20.0611 16.6117 20.184 16.3946 20.2216 16.1559C20.2783 15.797 20.016 15.3599 19.4914 14.4856L18.9134 13.5222L18.8836 13.4725C18.4274 12.7059 18.1034 11.8698 17.9237 10.9994C15.1975 10.9586 13 8.73592 13 6C13 5.00331 13.2917 4.07473 13.7942 3.29494ZM16.2741 4.98883C16.0999 5.28551 16 5.63109 16 6C16 6.94979 16.6621 7.74494 17.5499 7.94914C17.4205 6.82135 16.9609 5.80382 16.2741 4.98883Z" />
      <path d="M9 17C9 17.394 9.0776 17.7841 9.22836 18.1481C9.37913 18.512 9.6001 18.8427 9.87868 19.1213C10.1573 19.3999 10.488 19.6209 10.8519 19.7716C11.2159 19.9224 11.606 20 12 20C12.394 20 12.7841 19.9224 13.1481 19.7716C13.512 19.6209 13.8427 19.3999 14.1213 19.1213C14.3999 18.8427 14.6209 18.512 14.7716 18.1481C14.9224 17.7841 15 17.394 15 17L12 17H9Z" />
      <circle cx="18" cy="6" r="2.5" />
    </svg>
  ),
  displayName: "Bell",
});

export const CheckIcon = createIcon({
  path: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.3333 15.9998C29.3333 23.3636 23.3638 29.3332 16 29.3332C8.6362 29.3332 2.66667 23.3636 2.66667 15.9998C2.66667 8.63604 8.6362 2.6665 16 2.6665C23.3638 2.6665 29.3333 8.63604 29.3333 15.9998ZM23.3628 12.7177C23.7593 12.3333 23.769 11.7002 23.3846 11.3037C23.0001 10.9072 22.367 10.8975 21.9705 11.2819L14.4167 18.6069L11.3628 15.6456C10.9663 15.2611 10.3332 15.2708 9.94877 15.6673C9.5643 16.0638 9.57404 16.6969 9.97052 17.0814L13.7205 20.7177C14.1084 21.0939 14.7249 21.0939 15.1128 20.7177L23.3628 12.7177Z"
      />
    </svg>
  ),
  displayName: "CheckIcon",
});

export const MenuLeft = createIcon({
  path: (
    <svg>
      <path d="M14.5716 22.2857L9.42871 12L14.5716 1.71429" />
    </svg>
  ),
  displayName: "MenuLeft",
});

export const InfoStroke = createIcon({
  path: (
    <svg>
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
      <path d="M12 16V12" />
      <path d="M12 8H12.01" />
    </svg>
  ),
  displayName: "InfoStroke",
});
export const PlusIcon = createIcon({
  path: (
    <svg>
      <path d="M12 5V19" />
      <path d="M5 12H19" />
    </svg>
  ),
  displayName: "PlusIcon",
});
export const TriangleUp = createIcon({
  path: (
    <svg>
      <path d="M10.3633 4.85092L2.25653 17.3485C2.08938 17.6158 2.00094 17.9188 2.00001 18.2275C1.99907 18.5361 2.08567 18.8396 2.25119 19.1078C2.41671 19.3759 2.65538 19.5994 2.94346 19.7559C3.23153 19.9125 3.55897 19.9966 3.8932 20H20.1068C20.441 19.9966 20.7685 19.9125 21.0565 19.7559C21.3446 19.5994 21.5833 19.3759 21.7488 19.1078C21.9143 18.8396 22.0009 18.5361 22 18.2275C21.9991 17.9188 21.9106 17.6158 21.7435 17.3485L13.6367 4.85092C13.466 4.59116 13.2258 4.3764 12.9391 4.22735C12.6524 4.0783 12.329 4 12 4C11.671 4 11.3476 4.0783 11.0609 4.22735C10.7742 4.3764 10.534 4.59116 10.3633 4.85092Z" />
    </svg>
  ),
  displayName: "TriangleUp",
});
export const TriangleDown = createIcon({
  path: (
    <svg>
      <path d="M10.3633 19.1491L2.25653 6.65153C2.08938 6.38424 2.00094 6.08119 2.00001 5.77253C1.99907 5.46388 2.08567 5.16037 2.25119 4.89222C2.41671 4.62406 2.65538 4.40061 2.94346 4.24407C3.23153 4.08754 3.55897 4.00339 3.8932 4H20.1068C20.441 4.00339 20.7685 4.08754 21.0565 4.24407C21.3446 4.40061 21.5833 4.62406 21.7488 4.89222C21.9143 5.16037 22.0009 5.46388 22 5.77253C21.9991 6.08119 21.9106 6.38424 21.7435 6.65153L13.6367 19.1491C13.466 19.4088 13.2258 19.6236 12.9391 19.7727C12.6524 19.9217 12.329 20 12 20C11.671 20 11.3476 19.9217 11.0609 19.7727C10.7742 19.6236 10.534 19.4088 10.3633 19.1491Z" />
    </svg>
  ),
  displayName: "TriangleDown",
});

export const InfoFill = createIcon({
  path: (
    <svg>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 11C12.5523 11 13 11.4477 13 12V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V12C11 11.4477 11.4477 11 12 11ZM12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9H12.01C12.5623 9 13.01 8.55228 13.01 8C13.01 7.44772 12.5623 7 12.01 7H12Z"
      />
    </svg>
  ),
  displayName: "InfoFill",
});

export const MoveIcon = createIcon({
  path: (
    <svg>
      <path d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z" />
      <path d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z" />
      <path d="M19 17C19.5523 17 20 16.5523 20 16C20 15.4477 19.5523 15 19 15C18.4477 15 18 15.4477 18 16C18 16.5523 18.4477 17 19 17Z" />
      <path d="M19 9C19.5523 9 20 8.55228 20 8C20 7.44772 19.5523 7 19 7C18.4477 7 18 7.44772 18 8C18 8.55228 18.4477 9 19 9Z" />
      <path d="M5 17C5.55228 17 6 16.5523 6 16C6 15.4477 5.55228 15 5 15C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17Z" />
      <path d="M5 9C5.55228 9 6 8.55228 6 8C6 7.44772 5.55228 7 5 7C4.44772 7 4 7.44772 4 8C4 8.55228 4.44772 9 5 9Z" />
    </svg>
  ),
  displayName: "MoveIcon",
});

export const RightArrowIcon = createIcon({
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke-width="1px"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  displayName: "RightArrowIcon",
});

export const LeftArrowIcon = createIcon({
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 18L9 12L15 6"
        stroke-width="1px"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  displayName: "LeftArrowIcon",
});

export const Search = createIcon({
  path: (
    <svg>
      <circle cx="11" cy="11" r="7" />
      <path d="M20 20L17 17" />
    </svg>
  ),
  displayName: "Search",
});

export const CalendarIcon = createIcon({
  path: (
    <svg>
      <path d="M9 11H7V13H9V11ZM13 11H11V13H13V11ZM17 11H15V13H17V11ZM19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z" />
      <path d="M7 16H9V18H7V16Z" />
      <path d="M11 16H13V18H11V16Z" />
      <path d="M15 16H17V18H15V16Z" />
    </svg>
  ),
  displayName: "Calendar",
});

// Typography

export const Title = (props) => {
  return (
    <Heading className={`${styles.title}`} {...props}>
      {props.children}
    </Heading>
  );
};

export const CustomLink = (props) => {
  if (props.onClick !== undefined) {
    return (
      <Text className={`${styles.link}`} {...props}>
        {props.children}
      </Text>
    );
  } else {
    return (
      <Text
        className={`${styles.link}`}
        {...props}
        onClick={() => window.open(props.href, "_blank")}
      >
        {props.children}
      </Text>
    );
  }
};

export const ItemHeading = (props) => {
  return (
    <Text className={`${styles.itemHeading}`} {...props}>
      {props.children}
    </Text>
  );
};

export const Notes = (props) => {
  return (
    <Text className={`${styles.notes}`} {...props}>
      {props.children}
    </Text>
  );
};

export const CustomText = (props) => {
  return (
    <Text className={`${styles.innerText}`} {...props}>
      {props.children}
    </Text>
  );
};

// Panels

export const Background = (props) => {
  return <div className={styles.background}>{props.children}</div>;
};

// Inputs

let customStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--dark-gray)",
    color: "var(--light-gray)",
    border: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--dark-gray)",
    border: "none",
    color: "var(--light-gray)",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--dark-gray)",
    border: "none",
    color: "var(--light-gray)",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--dark-gray)",
    width: "257px",
    height: "40px",
    border: "none",
    color: "var(--light-gray)",
    marginRight: "5px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--dark-gray)",
    border: "none",
    color: "var(--white)",
  }),
};
const customStylesDarker = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    color: "var(--light-gray)",
    border: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    border: "none",
    color: "var(--light-gray)",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    border: "none",
    color: "var(--light-gray)",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    width: "auto",
    height: "40px",
    border: "none",
    color: "var(--light-gray)",
    marginRight: "5px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    border: "none",
    color: "var(--white)",
  }),
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <ChevronUp /> : <ChevronDown />}
      </components.DropdownIndicator>
    )
  );
};

// {dark, placeholder, options, onChange}
export const DropDown = (props) => {
  let overrideStyleString = `${styles.dropdown}`;

  if (props.dark === true) {
    overrideStyleString = overrideStyleString + ` ${styles.darkDropdown}`;
    customStyles = customStylesDarker;
  }

  return (
    <Select
      isLoading={props.loading}
      id={props.id}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      styles={customStyles}
      className={overrideStyleString}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ...props.components,
      }}
      {...props}
    />
  );
};

export const CreatableDropDown = (props) => {
  let overrideStyleString = `${styles.dropdown} ${styles.dropdownCreatable}`;

  if (props.dark === true) {
    overrideStyleString = overrideStyleString + ` ${styles.darkDropdown}`;
    customStyles = customStylesDarker;
  }

  return (
    <Creatable
      isLoading={props.loading}
      id={props.id}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      styles={customStyles}
      className={overrideStyleString}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ...props.components,
      }}
      {...props}
      minWidth="120px !important"
    />
  );
};

export const CustomSwitch = (props) => {
  if (props.defaultChecked === true) {
    return (
      <Switch size="lg" {...props} className={styles.customSwitchActive} />
    );
  } else {
    return <Switch size="lg" {...props} className={styles.customSwitch} />;
  }
};

export const CustomDateRangePicker = (props) => {
  return (
    <DateRangePicker
      className={`${styles.dateRangePicker} `}
      dayPlaceholder="DD"
      monthPlaceholder="MM"
      yearPlaceholder="YYYY"
      format="MM.dd.yyyy"
      rangeDivider="-"
      {...props}
    />
  );
};

export const CustomInputGroup = (props) => {
  return (
    <VStack className={styles.inputGroup} {...props}>
      {props.children}
    </VStack>
  );
};

export const OverlayButton = (props) => {
  const [styleClass, setClass] = useState("");
  const [customZIndex, setCustomZIndex] = useState(0);

  const openSearchOnKeyPress = (event) => {
    if (event.altKey === true && event.code === "KeyS") {
      setClass(styles.blockOverlayActive);
      setCustomZIndex(2);
    }
  };

  useEffect(() => {
    document.addEventListener(
      "keydown",
      (event) => openSearchOnKeyPress(event),
      false
    );
  }, []);

  return (
    <Box flex="2">
      <Box
        className={styleClass}
        onClick={() => {
          setClass("");
          setCustomZIndex(0);
        }}
      />
      <Box
        onClick={() => {
          setClass(styles.blockOverlayActive);
          setCustomZIndex(2);
        }}
        zIndex={customZIndex}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export const IconInput = (props) => {
  return (
    <InputGroup className={styles.textInputFrame} maxW={props.maxWidth}>
      <InputLeftElement
        children={props.icon}
        className={styles.textInputLeftIcon}
      />
      <Input className={styles.textInput} {...props} ref={props.inputRef} />
      <InputRightElement
        {...props.rightSide}
        width="110px"
        textAlign={"right"}
        justifyContent="flex-end"
        pr="10px"
      />
    </InputGroup>
  );
};

export const CustomDatePicker = (props) => {
  let styleC = "";
  if (props.dark === true) {
    styleC = "dark-bg";
  }

  return (
    <ReactDatePicker
      preventOpenOnFocus={true}
      className={"react-datepicker" + " " + styleC}
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
      renderCustomHeader={({
        date,
        monthDate,
        customHeaderCount,
        decreaseMonth,
        increaseMonth,
      }) => {
        return (
          <div>
            <button
              aria-label="Previous Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--previous"
              }
              onClick={decreaseMonth}
            >
              <LeftArrowIcon className={"react-datepicker-icon-left"} />
            </button>
            <span className="react-datepicker__current-month">
              {moment(date).format("MMMM YYYY")}
            </span>
            <button
              aria-label="Next Month"
              className={
                "react-datepicker__navigation react-datepicker__navigation--next"
              }
              onClick={increaseMonth}
            >
              <RightArrowIcon className={"react-datepicker-icon-right"} />
            </button>
          </div>
        );
      }}
      {...props}
    />
  );
};

export const CustomCheckBox = (props) => {
  return (
    <Checkbox className={"checkbox"} {...props}>
      {props.children}
    </Checkbox>
  );
};

export const TextInput = (props) => {
  let overrideStyleString = `${styles.textInput}`;

  if (props.dark === true) {
    overrideStyleString = overrideStyleString + ` ${styles.textInputDark}`;
  }

  return (
    <InputGroup className={styles.textInputFrame}>
      <Input className={overrideStyleString} {...props} />

      {props.leftComponent}

      {props.toggleShow === true && (
        <InputRightElement className={styles.showInputElement} minW="110px">
          <Button
            className={styles.showButton}
            h="1.75rem"
            onClick={props.toggleShowFunc}
          >
            {props.toggleShowContents}
          </Button>
        </InputRightElement>
      )}
      {props.children}

      {props.rightComponent}
    </InputGroup>
  );
};

export const CustomTextArea = (props) => {
  return (
    <InputGroup className={styles.textInputFrame}>
      <Textarea
        className={`${styles.textInput} ${styles.textInputDark} ${styles.textArea}`}
        {...props}
      />
      {props.children}
    </InputGroup>
  );
};

// Structure

export const CustomTabsContainer = (props) => {
  return (
    <Tabs className={styles.tabs} {...props} mt="2" isLazy colorScheme={"blue"}>
      {props.children}
    </Tabs>
  );
};

export const CustomTab = (props) => {
  return (
    <Tab className={styles.tab} id={props.id}>
      {props.children}
    </Tab>
  );
};

export const CustomTabPanels = (props) => {
  return (
    <TabPanels
      // width="100%"
      height="100%"
      className={styles.tabPanels}
    >
      {props.children}
    </TabPanels>
  );
};

export const CustomTabList = (props) => {
  return (
    <TabList
      sx={{
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
      {...props}
      className={styles.tabList}
    >
      {props.children}
    </TabList>
  );
};

// Guide components

export const GuideTag = () => {
  return null;
  return (
    <Tag
      backgroundColor="var(--bg)"
      mb="20px"
      borderRadius={"20px"}
      paddingLeft="20px"
      paddingRight={"20px"}
    >
      <span style={{ color: "var(--white)" }}>Guide </span>
    </Tag>
  );
};

export const LineItem = (props) => {
  return (
    <ListItem fontSize="16px" mt="8px" color="var(--white)">
      <ListIcon as={CheckCircleIcon} color="green.500" />
      {props.children}
    </ListItem>
  );
};

export const GuideHighlightText = (props) => {
  const addHighlightClass = (className) => {
    try {
      document.querySelector("." + className).classList.add("highlight");
    } catch (e) {
      console.log(e);
    }
  };

  const removeHighlightClass = (className) => {
    try {
      document.querySelector("." + className).classList.remove("highlight");
    } catch (e) {
      console.log(e);
    }
  };

  const moveClass = (className, x, y, z) => {
    try {
      document.querySelector(
        "." + className
      ).style.transform = `translateX(${x}%) translateY(${y}%) scale(${z})`;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Text
      display="inline-block"
      cursor={"pointer"}
      color="var(--accent)"
      textDecoration="underline"
      textDecorationStyle={"dotted"}
      textDecorationColor={"var(--accent)"}
      textDecorationThickness={"3px"}
      fontSize="16px"
      onMouseEnter={() => {
        addHighlightClass(props.highlightClass);
        moveClass("demoSVG", props.x, props.y, props.z);
      }}
      onMouseLeave={() => {
        removeHighlightClass(props.highlightClass);
      }}
    >
      {props.children}
    </Text>
  );
};

//Inset Tabs

export const InsetTabsContainer = (props) => {
  return (
    <Tabs className={styles.insetTabs} {...props} mt="2" isLazy>
      {props.children}
    </Tabs>
  );
};

export const InsetTab = (props) => {
  return (
    <Tab id={props.id} key={props.id} className={styles.insetTab}>
      {props.children}
    </Tab>
  );
};

export const InsetTabPanels = (props) => {
  return (
    <TabPanels height="100%" className={styles.insetTabPanels}>
      {props.children}
    </TabPanels>
  );
};

export const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" flex="1">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "blue.600",
          color: "white",
          borderColor: "blue.600",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        textAlign={"center"}
        py={1}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export const NotificationItem = ({
  index,
  status,
  type,
  threshold,
  commMethod,
  value,
  deleteNotification,
}) => {
  console.log({
    index,
    status,
    type,
    threshold,
    commMethod,
    value,
    deleteNotification,
  });
  return (
    <CustomTr key={"row-" + index}>
      <CustomTd>{status}</CustomTd>
      <CustomTd>{type}</CustomTd>
      <CustomTd>{threshold}</CustomTd>
      <CustomTd>{value.value}</CustomTd>
      <CustomTd>{moment(value.dateCreated).format("Do MMM YYYY")}</CustomTd>
      <CustomTd>{moment(value.expiryDate).format("Do MMM YYYY")}</CustomTd>
      <CustomTd>{value.commMethod === "EMAIL" ? "Email" : "SMS"}</CustomTd>
      <CustomTd>
        <CustomIconButton
          onClick={() => deleteNotification(value._id)}
          icon={<DeleteIcon />}
        />
      </CustomTd>
    </CustomTr>
  );
};

export const InsetTabList = (props) => {
  let color = "var(--dark-gray)";

  if (props.dark === true) {
    color = "var(--bg)";
  }

  return (
    <TabList
      sx={{
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
        background: color,
      }}
      className={styles.insetTabList}
      {...props}
      height={props.tall === true ? "48px" : "40px"}
    >
      {" "}
      {props.children}
    </TabList>
  );
};

// Widgets

export const WidgetPanelWithOverFlow = (props) => {
  return (
    <Box
      className={styles.widgetPanelWithOverFlow}
      sx={{
        html: {
          "scrollbar-face-color": "var(--bg)",
          "scrollbar-base-color": "var(--bg)",
          "scrollbar-3dlight-color": "var(--bg)",
          "scrollbar-highlight-color": "var(--bg)",
          "scrollbar-track-color": "#000",
          "scrollbar-arrow-color": "#000",
          "scrollbar-shadow-color": "var(--bg)",
          "scrollbar-dark-shadow-color": "var(--bg)",
        },

        "&::-webkit-scrollbar": { width: "8px", height: "3px" },
        "&::-webkit-scrollbar-button": { "background-color": "#666;" },
        "&::-webkit-scrollbar-track": { "background-color": "var(--bg)" },
        "&::-webkit-scrollbar-track-piece": { "background-color": "#000" },
        "&::-webkit-scrollbar-thumb": {
          height: "50px",
          "background-color": "#666",
          "border-radius": "3px",
        },
        "&::-webkit-scrollbar-corner": { "background-color": "var(--bg)" },
        "&::-webkit-resizer": { "background-color": "#666" },
      }}
    >
      {props.children}
    </Box>
  );
};

export const WidgetPanel = (props) => {
  return (
    <Box className={styles.widgetPanel} overflow="hidden" {...props}>
      {props.children}
    </Box>
  );
};

export const WidgetPanelNoBackground = (props) => {
  return (
    <Box
      className={styles.widgetPanelNoBackground}
      overflow="hidden"
      {...props}
    >
      {props.children}
    </Box>
  );
};

export const SocialSharePanel = (props) => {
  return (
    <Box className={styles.socialSharePanel} overflow="hidden" {...props}>
      {props.children}
    </Box>
  );
};

export const WidgetHeader = (props) => {
  return (
    <HStack className={styles.widgetHeader}>
      <Text className={styles.widgetTitle}>{props.label}</Text>

      {props.hideControls !== true && (
        <HStack className={styles.widgetButtonContainer}>
          <Icon
            className={`${styles.dragHandleIcon} drag-handle`}
            as={MoveIcon}
          />

          {props.hideInfo !== true && (
            <CustomToolTip label={props.tooltip}>
              <Icon className={styles.infoIcon} as={InfoFill} />
            </CustomToolTip>
          )}

          {props.hideClose !== true && (
            <Icon
              as={MdClose}
              className={`${styles.closeIcon} `}
              onClick={() => {
                props.toggleWidget();
              }}
            />
          )}
        </HStack>
      )}
    </HStack>
  );
};

export const WidgetHeaderTitleOnly = (props) => {
  return (
    <HStack className={styles.widgetHeader}>
      <Text className={styles.widgetTitle}>{props.label}</Text>
    </HStack>
  );
};

export const CustomToggle = (props) => {
  return <Toggle className={"custom-toggle"} {...props} />;
};

export const StatWidget = (props) => {
  // console.log(props)

  const [optionalLabel, setOptionalLabel] = useState(props.optionalLabel);
  // const [optionalValue, setOptionalValue] = useState(props.optional);
  const [toggle, setToggleF] = useState(
    localStorage.getItem(props.title + "-stat-container-" + optionalLabel) ===
      "true"
      ? true
      : false
  );
  const [optionalValue, setCommission] = useState(
    props.trades !== undefined && calculateCommission(props.trades)
  );

  const [tradeListIsOpen, setTradeListIsOpen] = useState(false);
  const [tradeList, setTradeList] = useState(props.filteredTrades);

  const [specficTradeData, setSpecficTradeData] = useState({});
  const [specficOptionData, setSpecficOptionData] = useState({});
  const [specficTradeOpen, setSpecficTradeOpen] = useState(false);

  const setToggle = (e) => {
    setToggleF(e.target.checked);
    localStorage.setItem(
      props.title + "-stat-container-" + optionalLabel,
      e.target.checked
    );
  };

  useEffect(() => {
    setCommission(
      props.trades !== undefined && calculateCommission(props.trades)
    );
  }, [toggle, props.trades]);

  if (props.type === "PERCENTAGE") {
    //Accurate
    return (
      <WidgetPanel>
        <WidgetHeader
          toggleWidget={(id) => props.toggleWidget(id)}
          hideControls={props.hideControls}
          label={props.title}
          tooltip={props.tooltip}
        ></WidgetHeader>
        <Text key={props.key} mb="12px" className={styles.statNumber}>
          {parseFloat(props.number).toFixed(2)}%
        </Text>
      </WidgetPanel>
    );
  } else if (props.type === "CURRENCY_PERCENTAGE_CHANGE") {
    //Accurate

    if (optionalValue !== undefined) {
    }

    return (
      <WidgetPanel>
        <WidgetHeader
          toggleWidget={(id) => props.toggleWidget(id)}
          hideControls={props.hideControls}
          label={props.title}
          tooltip={props.tooltip}
        ></WidgetHeader>

        {toggle === true && (
          <Text key={"2-" + props.key} mb="12px" className={styles.statNumber}>
            {currencyFormatter.format(props.number + optionalValue, {
              code: "USD",
            })}
          </Text>
        )}
        {toggle !== true && (
          <Text
            key={"1-" + props.key}
            id={props.id}
            mb="12px"
            className={styles.statNumber}
          >
            {currencyFormatter.format(props.number, { code: "USD" })}
          </Text>
        )}

        <HStack justifyContent={"space-between"}>
          {props.change > 0 && (
            <HStack>
              <Icon className={styles.positiveIcon} as={TriangleUp}></Icon>
              <Text className={styles.statChangePositive}>
                {props.change === Infinity ? (
                  <CustomToolTip label="You're seeing infinity here because you haven't set an account balance. Expand the left menu, click the +, and add an account balance.">
                    ∞
                  </CustomToolTip>
                ) : (
                  parseFloat(props.change).toFixed(2) + "%"
                )}
              </Text>
            </HStack>
          )}
          {props.change < 0 && (
            <HStack>
              <Icon className={styles.negativeIcon} as={TriangleDown}></Icon>
              <Text className={styles.statChangeNegative}>
                {" "}
                {props.change === -Infinity ? (
                  <CustomToolTip label="You're seeing infinity here because you haven't set an account balance. Expand the left menu, click the +, and add an account balance.">
                    ∞
                  </CustomToolTip>
                ) : (
                  parseFloat(props.change).toFixed(2) + "%"
                )}
              </Text>
            </HStack>
          )}
          {optionalValue && (
            <>
              <CustomToolTip
                label={
                  toggle === true
                    ? "Exclude " +
                      optionalLabel +
                      " of " +
                      currencyFormatter.format(optionalValue, { code: "USD" })
                    : "Include " +
                      optionalLabel +
                      " of " +
                      currencyFormatter.format(optionalValue, { code: "USD" })
                }
              >
                <CustomToggle
                  id={optionalLabel + "-status"}
                  defaultChecked={toggle}
                  onChange={setToggle}
                />
              </CustomToolTip>
            </>
          )}
        </HStack>
      </WidgetPanel>
    );
  } else if (props.type === "COMPARISON_CURRENCY") {
    return (
      <WidgetPanel>
        <WidgetHeader
          toggleWidget={(id) => {
            props.toggleWidget(id);
          }}
          hideControls={props.hideControls}
          label={props.title}
          tooltip={props.tooltip}
        ></WidgetHeader>
        <HStack
          width="100%"
          justifyContent={"space-around"}
          height="80%"
          alignItems={"center"}
        >
          <VStack>
            <Text className={styles.statChangePositiveLarge}>
              {currencyFormatter.format(props.leftSide, { code: "USD" })}
            </Text>
            <HStack>
              <Icon className={styles.positiveIcon} as={TriangleUp}></Icon>
              <Text fontSize="14px">Avg. Win</Text>
            </HStack>
          </VStack>
          <Text fontWeight="500" fontSize="14px" color="var(--light-gray)">
            V.s.
          </Text>
          <VStack>
            <Text className={styles.statChangeNegativeLarge}>
              {currencyFormatter.format(props.rightSide, { code: "USD" })}
            </Text>
            <HStack>
              <Icon className={styles.negativeIcon} as={TriangleDown}></Icon>
              <Text fontSize="14px">Avg. Loss</Text>
            </HStack>
          </VStack>
        </HStack>
      </WidgetPanel>
    );
  } else if (props.type === "CURRENCY_PERCENTAGE_CHANGE_INVERT") {
    //Accurate
    return (
      <WidgetPanel>
        <WidgetHeader
          toggleWidget={(id) => props.toggleWidget(id)}
          hideControls={props.hideControls}
          label={props.title}
          tooltip={props.tooltip}
        ></WidgetHeader>
        <Text mb="12px" className={styles.statNumber}>
          {props.change.toFixed(2)}%
        </Text>
        <HStack>
          <Text className={styles.statChangeNeutral}>
            Risk: {currencyFormatter.format(props.number, { code: "USD" })}
          </Text>
        </HStack>
      </WidgetPanel>
    );
  } else if (props.type === "TARGET_CURRENCY") {
    let newNumber = (props.number + "").replace("$", "");
    newNumber = parseFloat(newNumber);

    return (
      <WidgetPanel
        onClick={() => {
          console.log(tradeList);
          setTradeListIsOpen(true);
        }}
        cursor={tradeList && tradeList.length > 0 && "pointer"}
      >
        <WidgetHeader
          toggleWidget={(id) => props.toggleWidget(id)}
          hideControls={props.hideControls}
          label={props.title}
          tooltip={props.tooltip}
        ></WidgetHeader>
        <Text
          mb="12px"
          key={"text-target_currency-" + props.id + "-" + uuid()}
          className={styles.statNumber}
        >
          {currencyFormatter.format(props.number, { code: "USD" })}
        </Text>
        {!isNaN(props.change) && (
          <>
            {props.change < newNumber ? (
              <HStack>
                <Icon className={styles.positiveIcon} as={TriangleUp}></Icon>
                <Text className={styles.statChangePositive}>
                  {" "}
                  (Target{" "}
                  {currencyFormatter.format(props.change, { code: "USD" })})
                </Text>
              </HStack>
            ) : (
              <HStack>
                <Icon className={styles.negativeIcon} as={TriangleDown}></Icon>
                <Text className={styles.statChangeNegative}>
                  {" "}
                  (Target{" "}
                  {currencyFormatter.format(props.change, { code: "USD" })})
                </Text>
              </HStack>
            )}
          </>
        )}
        {props.children}

        {tradeList && tradeList.length > 0 && (
          <TradeDetailModal
            modalStatus={specficTradeOpen}
            setModal={(e) => setSpecficTradeOpen(e)}
            toggle={() => setSpecficTradeOpen(!specficTradeOpen)}
            tradeData={specficTradeData}
            optionData={specficOptionData}
          />
        )}

        {tradeList && tradeList.length > 0 && (
          <TradeList
            isOpen={tradeListIsOpen}
            onClose={() => setTradeListIsOpen(false)}
            tradeList={tradeList}
            setTradeData={(payload) => {
              setSpecficTradeData(payload);
              setSpecficOptionData(payload.optionData);
              setSpecficTradeOpen(true);
            }}
          />
        )}
      </WidgetPanel>
    );
  } else if (props.type === "TARGET_PERCENTAGE") {
    return (
      <WidgetPanel>
        <WidgetHeader
          toggleWidget={(id) => props.toggleWidget(id)}
          hideControls={props.hideControls}
          label={props.title}
          tooltip={props.tooltip}
        ></WidgetHeader>
        <Text mb="12px" className={styles.statNumber}>
          {parseFloat(props.number).toFixed(2)}%
        </Text>
        {!isNaN(props.change) && (
          <>
            {props.change < parseFloat(props.number) ? (
              <HStack>
                <Icon className={styles.positiveIcon} as={TriangleUp}></Icon>
                <Text className={styles.statChangePositive}>
                  {" "}
                  (Target {props.change}%)
                </Text>
              </HStack>
            ) : (
              <HStack>
                <Icon className={styles.negativeIcon} as={TriangleDown}></Icon>
                <Text className={styles.statChangeNegative}>
                  {" "}
                  (Target {props.change}%)
                </Text>
              </HStack>
            )}
          </>
        )}
      </WidgetPanel>
    );
  } else {
    return (
      <WidgetPanel
        onClick={() => {
          setTradeListIsOpen(true);
        }}
        cursor={tradeList && tradeList.length > 0 && "pointer"}
      >
        <WidgetHeader
          toggleWidget={(id) => props.toggleWidget(id)}
          hideControls={props.hideControls}
          label={props.title}
          tooltip={props.tooltip}
        ></WidgetHeader>
        <Text mb="12px" className={styles.statNumber}>
          {parseFloat(props.number).toFixed(0)}
        </Text>

        {tradeList && tradeList.length > 0 && (
          <TradeDetailModal
            modalStatus={specficTradeOpen}
            setModal={(e) => setSpecficTradeOpen(e)}
            toggle={() => setSpecficTradeOpen(!specficTradeOpen)}
            tradeData={specficTradeData}
            optionData={specficOptionData}
          />
        )}

        {tradeList && tradeList.length > 0 && (
          <TradeList
            isOpen={tradeListIsOpen}
            onClose={() => setTradeListIsOpen(false)}
            tradeList={tradeList}
            setTradeData={(payload) => {
              setSpecficTradeData(payload);
              setSpecficOptionData(payload.optionData);
              setSpecficTradeOpen(true);
            }}
          />
        )}
      </WidgetPanel>
    );
  }
};

// Sidebar

export const ToolTipSidebar = (props) => {
  if (window.localStorage.getItem("menuPreference") === "hidden") {
    return (
      <Box maxWidth="70px">
        <CustomToolTip
          label={props.label}
          placement="right"
          transform="translateX(-80px)"
        >
          {props.children}
        </CustomToolTip>
      </Box>
    );
  } else if (window.localStorage.getItem("menuPreference") === "visible") {
    return <>{props.children}</>;
  } else {
    return <>{props.children}</>;
  }
};

export const SideBarButton = (props) => {
  if (props.short === true) {
    return (
      <ToolTipSidebar label={props.label}>
        <HStack
          onClick={props.onClick}
          className={styles.sideBarMenuItemRowShort}
        >
          <IconButton
            {...props}
            className={styles.sideBarIconShort}
            icon={props.icon}
          />
        </HStack>
      </ToolTipSidebar>
    );
  } else if (props.isActive === true) {
    return (
      <ToolTipSidebar label={props.label}>
        <HStack
          onClick={props.onClick}
          className={styles.sideBarMenuItemRowActive}
        >
          <IconButton
            {...props}
            className={styles.sideBarIcon}
            icon={props.icon}
          />

          {!props.menuOpen && !props.mobile && (
            <Text className={styles.sideBarText}>{props.label}</Text>
          )}
          {props.mobile && (
            <Text className={styles.sideBarText}>{props.label}</Text>
          )}

          {/* </CustomToolTip> */}
        </HStack>
      </ToolTipSidebar>
    );
  } else {
    return (
      <ToolTipSidebar label={props.label}>
        <HStack onClick={props.onClick} className={styles.sideBarMenuItemRow}>
          <IconButton
            {...props}
            className={styles.sideBarIcon}
            icon={props.icon}
          />
          {!props.menuOpen && !props.mobile && (
            <Text className={styles.sideBarTextInactive}>{props.label}</Text>
          )}
          {props.mobile && (
            <Text className={styles.sideBarTextInactive}>{props.label}</Text>
          )}
        </HStack>
      </ToolTipSidebar>
    );
  }
};

export const SideBarProfileToggle = (props) => {
  if (props.profileName.length < 1) {
    return null;
  }
  if (props.profileName === "CUSTOM_CREATE_COMPONENT") {
    return (
      <HStack
        {...props}
        className={styles.sideBarRow}
        justifyContent="flex-start"
      >
        <IconButton
          className={` ${styles.plusIcon} plusicon`}
          aria-label="manage portfolios"
          icon={<PlusIcon />}
        />
        <VStack className={styles.sideBarRowTextContainer}>
          <Text className={styles.sideBarTextInactive} id="manageportfolio">
            Manage portfolios
          </Text>
        </VStack>
      </HStack>
    );
  }
  if (props.isActive === true) {
    return (
      <HStack
        onClick={() => {
          props.toggleFunc();
        }}
        className={`${styles.sideBarRow} ${styles.sideBarRowActive}`}
      >
        <Button
          {...props}
          className={styles.sideBarProfileIcon}
          aria-label="profile selected"
        >
          {props.profileName[0].toUpperCase()}
        </Button>
        <div></div>
        <VStack className={styles.sideBarRowTextContainer}>
          <CustomToolTip
            label={
              <span>
                <Kbd>alt</Kbd> + <Kbd>{props.index}</Kbd>
              </span>
            }
          >
            <Text className={styles.sideBarText}>{props.profileName}</Text>
          </CustomToolTip>
        </VStack>
      </HStack>
    );
  } else {
    return (
      <HStack
        onClick={() => {
          props.toggleFunc();
        }}
        className={styles.sideBarRow}
      >
        <Button
          {...props}
          className={styles.sideBarProfileIconInactive}
          aria-label="select profile"
          icon={props.icon}
        >
          {props.profileName[0].toUpperCase()}
        </Button>
        <div></div>

        <VStack className={styles.sideBarRowTextContainer}>
          <CustomToolTip
            label={
              <span>
                <Kbd>alt</Kbd> + <Kbd>{props.index}</Kbd>
              </span>
            }
          >
            <Text className={styles.sideBarTextInactive}>
              {props.profileName}
            </Text>
          </CustomToolTip>
        </VStack>
      </HStack>
    );
  }
};

// Tables

export const TableFrame = (props) => {
  return (
    <Table {...props} className={styles.tablesFrame}>
      {props.children}
    </Table>
  );
};

export const TableHeader = (props) => {
  return (
    <Thead {...props} className={styles.tablesHeader}>
      {props.children}
    </Thead>
  );
};

export const StockLogo = ({ stock, size }) => {
  if (size === undefined) {
    size = 30;
  }
  if (stock === undefined) {
    return <Box></Box>;
  } else {
    return (
      <Image
        htmlHeight={size}
        htmlWidth={size}
        src={"https://eodhistoricaldata.com/img/logos/US/" + stock + ".png"}
        borderRadius={"3px"}
        fallback={
          <Image
            htmlHeight={size}
            htmlWidth={size}
            borderRadius={"5px"}
            src={
              "https://eodhistoricaldata.com/img/logos/US/" +
              stock.toLowerCase() +
              ".png"
            }
            fallback={
              <Box w={size + "px"} h={size + "px"} borderRadius={"5px"}>
                <Text
                  display="flex"
                  w={size + "px"}
                  h={size + "px"}
                  backgroundColor={"var(--bg)"}
                  justifyContent={"center"}
                  alignItems="center"
                  borderRadius={"4px"}
                  textAlign={"center"}
                  verticalAlign="middle"
                  lineHeight={"24px"}
                  fontSize="30px"
                  textOverflow={"ellipsis"}
                >
                  {stock[0].toUpperCase()}
                </Text>
              </Box>
            }
          />
        }
      />
    );
  }
};

export class StockPriceWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockPrice: 0,
      loading: true,
    };
  }
  componentDidMount() {
    apiCalls.getStockPrice(APIEndpoint, this.props.code).then((stockPrice) => {
      // console.log(stockPrice);
      if (stockPrice && stockPrice.data && stockPrice.data.data) {
        this.setState({
          loading: false,
          valid: true,
          price: stockPrice.data.data,
          code: this.props.code,
        });
      } else {
        this.setState({
          loading: false,
          valid: true,
          price: stockPrice.data,
          code: this.props.code,
        });
      }
    });
  }

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    try {
      let dayChange = this.state.price.change_p;
      let price = this.state.price.close;
      let code = this.state.price.stockTicker;
      return (
        <SimpleGrid columns={2} display="flex">
          <Box marginRight="20px" marginBottom="0px">
            <HStack>
              <StockLogo stock={code} />

              <VStack ml="8px" alignItems="flex-start" height={"56px"}>
                <Text
                  textAlign={"left"}
                  fontSize={"12px"}
                  fontWeight="400"
                  lineHeight={"140%"}
                  color="var(--light-gray)"
                >
                  {code}
                </Text>
                {this.props.name && (
                  <Text
                    color={"var(--white)"}
                    textAlign={"left"}
                    fontSize={"20px"}
                    fontWeight="700"
                    lineHeight={"140%"}
                    id="stockname"
                  >
                    {this.props.name}
                  </Text>
                )}
              </VStack>
            </HStack>
          </Box>

          <Box
            borderLeft={"solid 1px var(--gray)"}
            marginRight="20px"
            marginBottom="0px"
            maxW={"200px"}
          >
            <VStack ml="8px" alignItems="flex-start">
              <Text
                textAlign={"left"}
                fontSize={"12px"}
                fontWeight="400"
                lineHeight={"140%"}
                color="var(--light-gray)"
              >
                {"STOCK PRICE"}
              </Text>

              <HStack>
                <Text
                  color={"var(--white)"}
                  textAlign={"left"}
                  fontSize={"20px"}
                  fontWeight="700"
                  lineHeight={"140%"}
                >
                  {currencyFormatter.format(price, { code: "USD" })}
                </Text>

                {dayChange > 0 && (
                  <Box
                    background={"var(--green)"}
                    borderRadius="4px"
                    margin="8px"
                    padding="6px 6px 6px 6px"
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ChevronUp fill="var(--white)" stroke="var(--white)" />
                    <Text
                      color={"var(--white)"}
                      fontSize={"12px"}
                      fontWeight="500"
                      lineHeight={"140%"}
                    >
                      {dayChange}%
                    </Text>
                  </Box>
                )}

                {dayChange < 0 && (
                  <Box
                    background={"var(--red)"}
                    borderRadius="4px"
                    margin="8px"
                    padding="6px 6px 6px 6px"
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ChevronDown fill="var(--white)" stroke="var(--white)" />
                    <Text
                      color={"var(--white)"}
                      fontSize={"12px"}
                      fontWeight="500"
                      lineHeight={"140%"}
                    >
                      {dayChange}%
                    </Text>
                  </Box>
                )}
              </HStack>
            </VStack>
          </Box>
        </SimpleGrid>
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}

export const CustomPopover = (props) => {
  return (
    <Popover isLazy matchWidth trigger="hover" closeOnBlur={false}>
      {({ isOpen, onClose }) => {
        return (
          <>
            <PopoverTrigger>
              <Box role="button" children="Click">
                {props.button}
              </Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                onMouseLeave={(e) => {
                  e.preventDefault();
                }}
              >
                <PopoverArrow />
                <PopoverBody
                  bg="var(--bg)"
                  padding="0"
                  borderRadius={"6px"}
                  border="0"
                >
                  <Box
                    display={"flex"}
                    borderRadius={"6px"}
                    border="0"
                    justifyContent="center"
                    alignItems={"center"}
                    bg="var(--bg)"
                  >
                    {props.content}
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        );
      }}
    </Popover>
  );
};

export const CustomTr = (props) => {
  return (
    <Tr className={styles.tablesRow} {...props}>
      {props.children}
    </Tr>
  );
};

export const CustomTd = (props) => {
  return (
    <Td className={styles.customTd} {...props}>
      {props.children}
    </Td>
  );
};

export const CustomTh = (props) => {
  return (
    <Th {...props} className={styles.tablesHead}>
      {props.children}
    </Th>
  );
};

export const CustomTBody = (props) => {
  return (
    <Tbody className={styles.customTbody} {...props}>
      {props.children}
    </Tbody>
  );
};

// Modals

/// Tooltip

export const CustomToolTip = (props) => {
  return (
    <Tooltip label={props.label} className={styles.CustomToolTip} {...props}>
      <Box>{props.children}</Box>
    </Tooltip>
  );
};

export const CustomToolTipLeftAligned = (props) => {
  return (
    <Tooltip
      label={props.label}
      className={styles.CustomToolTipLeftAligned}
      {...props}
    >
      <Box>{props.children}</Box>
    </Tooltip>
  );
};

export const Glass = (props) => {
  return <Box className={styles.Glass}>{props.children}</Box>;
};

export const CustomDrawer = (props) => {
  return (
    <Drawer
      size="lg"
      className={styles.customDrawer}
      // placement="bottom"

      {...props}
    >
      {props.overlayEnabled && <DrawerOverlay />}
      <DrawerContent className={styles.customDrawerContent}>
        <DrawerBody
          paddingLeft={props.paddingLeft ? props.paddingLeft : "32px"}
          paddingRight={props.paddingRight ? props.paddingRight : "32px"}
        >
          {props.body}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const DataPanel = ({ label, data, color, change, format, type, id }) => {
  if (data === null || data === "" || data === undefined) {
    return null;
  }

  if (type === "CELL") {
    return (
      <Box
        borderLeft={"solid 1px var(--gray)"}
        marginRight="20px"
        marginBottom="20px"
      >
        <VStack pl="16px" alignItems="flex-start">
          <Text
            textAlign={"left"}
            fontSize={"17px"}
            fontWeight="400"
            lineHeight={"140%"}
            color="var(--light-gray)"
          >
            {label}
          </Text>
          <Box
            color={color ? color : "var(--white)"}
            textAlign={"left"}
            fontSize={"16px"}
            fontWeight="700"
            lineHeight={"140%"}
          >
            {data}
          </Box>
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      borderLeft={"solid 1px var(--gray)"}
      marginRight="20px"
      marginBottom="20px"
    >
      <VStack pl="16px" alignItems="flex-start">
        <Text
          textAlign={"left"}
          fontSize={"17px"}
          fontWeight="400"
          lineHeight={"140%"}
          color="var(--light-gray)"
        >
          {label}
        </Text>
        <Text
          id={id}
          color={color ? color : "var(--white)"}
          textAlign={"left"}
          fontSize={"28px"}
          fontWeight="700"
          lineHeight={"140%"}
        >
          {data}
        </Text>
        {change && change > data && (
          <HStack>
            <ChevronUp
              backgroundColor={"transparent"}
              fill="var(--green)"
              strokeWidth={"1.5px"}
              stroke="var(--green)"
            />
            <Text
              color={"var(--green)"}
              textAlign={"left"}
              fontSize={"14px"}
              fontWeight="500"
              lineHeight={"140%"}
            >
              {change && format(parseFloat(change).toFixed(2))}
            </Text>
          </HStack>
        )}
        {change && change < data && (
          <HStack>
            <ChevronDown
              backgroundColor={"transparent"}
              fill="var(--red)"
              strokeWidth={"1.5px"}
              stroke="var(--red)"
            />
            <Text
              color={"var(--red)"}
              textAlign={"left"}
              fontSize={"14px"}
              fontWeight="500"
              lineHeight={"140%"}
            >
              {change && format(parseFloat(change).toFixed(2))}
            </Text>
          </HStack>
        )}
        {change && change === data && (
          <HStack>
            <Text
              color={"var(--light-gray)"}
              textAlign={"left"}
              fontSize={"14px"}
              fontWeight="500"
              lineHeight={"140%"}
            >
              {change && format(parseFloat(change).toFixed(2))}
            </Text>
          </HStack>
        )}
      </VStack>
    </Box>
  );
};

export const TradeRowHeader = (props) => {
  return (
    <Box
      height="32px"
      mt="22px"
      bg="var(--dark-gray)"
      padding="14px"
      display="flex"
      justifyContent={"space-evenly"}
      width="100%"
      {...props}
    >
      <Box
        display={"grid"}
        gridTemplateColumns={"1fr 1fr 1fr 1fr 1fr 1fr"}
        gap="12px 12px"
        alignContent={"center"}
        justifyContent="start"
        alignItems={"center"}
        justifyItems="center"
        width="100%"
      >
        <Box className={styles.tradeRowHeaderText}>TICKER</Box>
        <Box className={styles.tradeRowHeaderText}>TYPE</Box>
        <Box className={styles.tradeRowHeaderText}>STATUS</Box>
        <Box className={styles.tradeRowHeaderText}>ACTION</Box>
        <Box className={styles.tradeRowHeaderText}>STRIKE</Box>
        <Box className={styles.tradeRowHeaderText}>PROFIT/LOSS</Box>
      </Box>
    </Box>
  );
};

export const TradeRow = (props) => {
  let action = props.action;
  if (action === "BUY_TO_OPEN") {
    action = "Buy to open";
  } else if (action === "SELL_TO_OPEN") {
    action = "Sell to open";
  } else if (action === "BUY") {
    action = "Buy";
  } else if (action === "SHORT") {
    action = "Short";
  }

  return (
    <Box
      height="72px"
      bg="var(--dark-gray)"
      padding="14px"
      display="flex"
      justifyContent={"space-evenly"}
      width="100%"
      {...props}
    >
      <Box
        display={"grid"}
        gridTemplateColumns={"1fr 1fr 1fr 1fr 1fr 1fr"}
        gap="12px 12px"
        alignContent={"center"}
        justifyContent="start"
        alignItems={"center"}
        justifyItems="center"
        width="100%"
        cursor={"pointer"}
      >
        <VStack>
          <Box
            fontSize="14px"
            fontWeight={500}
            color="var(--white)"
            textAlign={"left"}
          >
            {props.stockTicker === "SYSTEM_CASH" ? "Cash" : props.stockTicker}
          </Box>
          {/* <Box fontSize="14px" fontWeight={400} color="var(--light-gray)">{time}</Box> */}
        </VStack>
        {/* <Box fontSize="14px" fontWeight={400} color="var(--white)" padding="4px 12px 4px 12px" borderRadius="24px" bg={props.tradeType.toUpperCase() === 'STOCK' ? 'var(--trade-stock)' : props.tradeType === 'CALL' ? 'var(--trade-call)' : 'var(--trade-put)'}>{props.tradeType.toLowerCase().charAt(0).toUpperCase() + props.tradeType.toLowerCase().slice(1)}</Box> */}
        <TradeTypeComponent
          tradeType={props.tradeType}
          isDividendFlag={props.tradeData.isDividendFlag}
        />
        <PositionStatusComponent
          tradeData={props.tradeData}
          positionStatus={props.positionStatus}
        />
        <TradeActionComponent action={action} />
        <Text>{props.strikePrice}</Text>
        <VStack>
          {props.positionStatus === "CLOSED" && (
            <Box textAlign={"left"}>
              {" "}
              {currencyFormatter
                .format(props.pandl, { code: "USD" })
                .substring(
                  0,
                  currencyFormatter.format(props.pandl, { code: "USD" })
                    .length - 3
                )}
            </Box>
          )}
          {props.positionStatus === "CLOSED" && (
            <Box>
              {props.percentage > 0 ? (
                <Text textAlign={"left"} color="var(--green)" fontSize="14px">
                  <TriangleUp width="7px" fill="var(--green)" />{" "}
                  {props.percentage}%
                </Text>
              ) : (
                <Text textAlign={"left"} color="var(--red)" fontSize="14px">
                  <TriangleDown width="7px" fill="var(--red)" />{" "}
                  {Math.abs(props.percentage)}%
                </Text>
              )}
            </Box>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export const TradeActionComponent = (props) => {
  let action = props.action;
  if (!action) {
    return null;
  }

  if (props.action === "SELL_TO_OPEN") {
    action = "Sell to open";
  } else if (props.action === "BUY_TO_OPEN") {
    action = "Buy to open";
  } else if (props.action === "BUY") {
    action = "Buy";
  } else if (props.action === "SELL") {
    action = "Short";
  } else if (props.action === "DEPOSIT") {
    action = "Deposit";
  } else if (props.action === "WITHDRAW") {
    action = "Withdraw";
  }

  return (
    <Box
      height="28px"
      lineHight="100%"
      fontSize="14px"
      fontWeight={400}
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="var(--white)"
      textAlign={"left"}
    >
      {action}
    </Box>
  );
};

export const TradeTypeComponent = (props) => {
  if (!props.tradeType) {
    return null;
  }

  return (
    <Box
      height="28px"
      lineHight="100%"
      fontSize="14px"
      fontWeight={400}
      display="flex"
      justifyContent="center"
      alignItems="center"
      maxWidth="70px"
      color="var(--white)"
      padding="4px 12px 4px 12px"
      borderRadius="24px"
      bg={
        props.tradeType.toUpperCase() === "STOCK" &&
        props.isDividendFlag === false
          ? "var(--trade-stock)"
          : props.tradeType.toUpperCase() === "STOCK" &&
            props.isDividendFlag === true
          ? "var(--accent)"
          : props.tradeType === "CALL"
          ? "var(--trade-call)"
          : "var(--trade-put)"
      }
    >
      {props.isDividendFlag === true && <>Dividend</>}
      {props.isDividendFlag === false && (
        <>
          {props.tradeType.toLowerCase().charAt(0).toUpperCase() +
            props.tradeType.toLowerCase().slice(1)}
        </>
      )}
    </Box>
  );
};

export const ColorModeImageContainer = (props) => {
  const { colorMode, toggleColorMode } = useColorMode();

  if (colorMode === "light") {
    return <Image src={props.light} alt="Options Tracker" {...props} />;
  } else {
    return <Image src={props.dark} alt="Options Tracker" {...props} />;
  }
};

export const PositionStatusComponent = (props) => {
  if (!props.tradeData) {
    return <Box></Box>;
  }

  let tradeMarkedExpired = undefined;

  if (props.tradeData && props.tradeData.tradeMarkedExpired !== undefined) {
    tradeMarkedExpired = props.tradeData.tradeMarkedExpired;
  }

  if (
    props.tradeData.tradeType !== "STOCK" &&
    props.tradeData.positionStatus === "CLOSED"
  ) {
    if (
      moment(props.tradeData.dateClosed).format("DD/MM/YYYY") ===
      moment(props.tradeData.expiryDate).format("DD/MM/YYYY")
    ) {
      if (
        props.tradeData.premium ===
        props.tradeData.realisedProfitAndLoss / props.tradeData.contracts
      ) {
        tradeMarkedExpired = true;
      }
    }
  }

  if (!props.tradeData.positionStatus) {
    return null;
  }

  if (props.tradeData.assignedBool === true) {
    return (
      <Box
        id="positionstatuscomponent"
        height="28px"
        lineHight="100%"
        fontSize="14px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth="70px"
        fontWeight={400}
        color="var(--white)"
        padding="4px 12px 4px 12px"
        borderRadius="24px"
        bg={"var(--trade-assigned)"}
      >
        Assigned
      </Box>
    );
  }
  if (tradeMarkedExpired === true) {
    return (
      <Box
        id="positionstatuscomponent"
        height="28px"
        lineHight="100%"
        fontSize="14px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth="70px"
        fontWeight={400}
        color="var(--white)"
        padding="4px 12px 4px 12px"
        borderRadius="24px"
        bg={"var(--trade-expired)"}
      >
        Expired
      </Box>
    );
  } else {
    return (
      <Box
        id="positionstatuscomponent"
        height="28px"
        lineHight="100%"
        fontSize="14px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth="70px"
        fontWeight={400}
        color="var(--white)"
        padding="4px 12px 4px 12px"
        borderRadius="24px"
        bg={
          props.tradeData.positionStatus.toUpperCase() === "OPEN"
            ? "var(--trade-open)"
            : "var(--trade-closed)"
        }
      >
        {props.tradeData.positionStatus.toLowerCase().charAt(0).toUpperCase() +
          props.tradeData.positionStatus.toLowerCase().slice(1)}
      </Box>
    );
  }
};

export const CustomModal = (props) => {
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  let styleC = "";
  if (props.darkGrayBg === true) {
    styleC = styles.darkGrayBg;
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="full"
      isCentered
      className={`${styles.modal}`}
      scrollBehavior={scrollBehavior}
      {...props}
    >
      <ModalOverlay bg="#0000008f" />
      <ModalContent className={styles.modalContent + " " + styleC}>
        {props.header && (
          <ModalHeader padding={0} margin={0} mt="20px" mb="12px">
            <Box pl="40px">{props.header}</Box>
            <Divider mt="10px" mb="10px" />
          </ModalHeader>
        )}
        <ModalBody
          display="flex"
          minH="100px"
          flexDir="column"
          sx={{
            html: {
              "scrollbar-face-color": "var(--bg)",
              "scrollbar-base-color": "var(--bg)",
              "scrollbar-3dlight-color": "var(--bg)",
              "scrollbar-highlight-color": "var(--bg)",
              "scrollbar-track-color": "#000",
              "scrollbar-arrow-color": "#000",
              "scrollbar-shadow-color": "var(--bg)",
              "scrollbar-dark-shadow-color": "var(--bg)",
            },

            "&::-webkit-scrollbar": { width: "8px", height: "3px" },
            "&::-webkit-scrollbar-button": { "background-color": "#666;" },
            "&::-webkit-scrollbar-track": { "background-color": "var(--bg)" },
            "&::-webkit-scrollbar-track-piece": { "background-color": "#000" },
            "&::-webkit-scrollbar-thumb": {
              height: "50px",
              "background-color": "#666",
              "border-radius": "3px",
            },
            "&::-webkit-scrollbar-corner": { "background-color": "var(--bg)" },
            "&::-webkit-resizer": { "background-color": "#666" },
          }}
        >
          {props.body}
        </ModalBody>
        <ModalFooter>{props.footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const CustomModalBasic = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
      className={`${styles.modal} ${styles.basicModal}`}
      size="full"
      {...props}
    >
      <ModalOverlay bg="#0000008f" onClose={props.onClose} />
      <ModalContent className={`${styles.modalContent} ${styles.basicModal}`}>
        {props.footerEnabled && (
          <ModalHeader>
            <ModalCloseButton id="closebutton" />
          </ModalHeader>
        )}
        <ModalBody display="flex" minH="100px" flexDir="column">
          {props.body}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

// Tables

// data, keys

let chartTheme = {
  crosshair: {
    line: {
      stroke: "var(--white)",
      strokeWidth: 1,
      strokeOpacity: 0.35,
    },
  },
  // "labels": {
  //     text: {
  //         fontFamily: 'Inter',
  //         fontStyle: 'normal',
  //         fontWeight: '400',
  //         lineHeight: '140%',
  //         color: 'var(--red)'
  //     },
  // },

  textColor: "var(--light-gray)",
  // "annotations": {
  //     "text": {
  //         "fontSize": 14,
  //         "fill": "var(--red)",
  //         "outlineWidth": 0,
  //         "outlineColor": "#ffffff",
  //         "outlineOpacity": 1
  //     },
  // },
  tooltip: {
    container: {
      background: "transparent",
      opacity: 1,
      color: "white",
      fontSize: 16,
    },
  },

  grid: {
    line: {
      stroke: "var(--gray)",
      strokeWidth: "1px",
      "stroke-dasharray": 3,
    },
  },
  axis: {
    domain: {
      line: {
        stroke: "var(--light-gray)",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: "#333333",
      },
    },
    ticks: {
      line: {
        stroke: "var(--light-gray)",
        strokeWidth: 1,
      },
      text: {
        fontSize: 11,
        fill: "var(--light-gray)",
      },
    },
  },

  annotations: {
    text: {
      fontSize: 14,
      fill: "white",
      outlineWidth: 0,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },

  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: "var(--white)",
      },
    },
    text: {
      fontSize: 11,
      fill: "var(--white)",
    },
  },
};

export const theme = () => {
  return chartTheme;
};

const redZeroLine = [
  {
    axis: "y",
    value: 0,
    lineStyle: { stroke: "var(--red)", strokeWidth: 1 },
    legendOrientation: "vertical",
  },
];

export const PieChart = (props) => {
  let total = 0;
  props.data.filter((value) => {
    total += value.value;
  });

  return (
    <ResponsivePie
      theme={chartTheme}
      fit={true}
      tooltip={(props) => {
        return (
          <Glass>
            <VStack
              display={"flex"}
              justifyContent="center"
              alignItems={"flex-start"}
            >
              <Text className={styles.GlassTitle}> {props.datum.label}</Text>
              <Text className={styles.GlassText}>
                {" "}
                {currencyFormatter.format(props.datum.value, { code: "USD" })} (
                {((props.datum.value / total) * 100).toFixed(2)}%)
              </Text>
            </VStack>
          </Glass>
        );
      }}
      data={props.data}
      margin={{ top: 50, right: 80, bottom: 140, left: 80 }}
      valueFormat=" >-$"
      innerRadius={0.8}
      padAngle={0.7}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      // borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLinkLabelsSkipAngle={7}
      arcLinkLabelsDiagonalLength={28}
      // arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      // arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={"var(--white)"}
      // legends={[
      //     {
      //         dataFrom: 'keys',
      //         anchor: 'bottom',
      //         direction: 'row',
      //         translateX: 0,
      //         translateY: 70,
      //         itemsSpacing: 20,
      //         itemWidth: 100,
      //         itemHeight: 20,
      //         itemDirection: 'left-to-right',
      //         symbolSize: 16,
      //         symbolShape: 'circle'

      //     }
      // ]}
      enableArcLabels={false}
      labelTextColor={"var(--light-gray)"}
      labelFormat={(value) => {
        return <p>{value} (</p>;
      }}
      colors={[
        "var(--chart-blue)",
        "var(--chart-dark-blue)",
        "var(--chart-orange)",
        "var(--chart-baby-blue)",
        "var(--chart-swamp)",
      ]}
      arcLinkLabelsTextColor={"var(--white)"}
      {...props}
    />
  );
};

export const BarChart = (props) => {
  return (
    <ResponsiveBar
      theme={chartTheme}
      data={props.data}
      keys={props.keys}
      label={(node) => {
        return `${currencyFormatter.format(node.value, { code: "USD" })}`;
      }}
      tooltip={(props) => {
        let id = props.id;
        let value = props.value;
        return (
          <Glass>
            <VStack
              display={"flex"}
              justifyContent="center"
              alignItems={"flex-start"}
            >
              <Box>
                <Text className={styles.GlassTitle}> {id}</Text>
                <Text className={styles.GlassText}>
                  {" "}
                  {currencyFormatter.format(value, { code: "USD" })}
                </Text>
              </Box>

              {props.data && props.data.totalRealisedProfitAndLoss && (
                <Box>
                  <Divider mb="2" />
                  <Text className={styles.GlassTitle}>Realised P&L</Text>
                  <Text className={styles.GlassText}>
                    {currencyFormatter.format(
                      props.data.totalRealisedProfitAndLoss,
                      { code: "USD" }
                    )}
                  </Text>
                </Box>
              )}
            </VStack>
          </Glass>
        );
      }}
      margin={{ top: 50, right: 29, bottom: 220, left: 80 }}
      // padding={1}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={[
        "var(--chart-blue)",
        "var(--chart-dark-blue)",
        "var(--chart-orange)",
        "var(--chart-baby-blue)",
        "var(--chart-swamp)",
      ]}
      markers={redZeroLine}
      labelTextColor={"var(--light-gray)"}
      labelFormat={(value) => {
        return <p>{value}</p>;
      }}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 0,
        format: (value) => currencyFormatter.format(value, { code: "USD" }),
      }}
      axisBottom={{
        tickRotation: 45,
        tickSize: 5,
        legendPosition: "center",
        format: (value) => {
          let frequency = props.frequency;
          if (frequency === "ANNUALLY") {
            return moment(value).format("YY");
          } else if (frequency === "MONTHLY") {
            return moment(value, "MMMM YYYYY").format("MMM YY");
          } else if (frequency === "WEEKLY") {
            return moment(value, "w YYYY").format("w YY");
          } else if (frequency === "DAILY") {
            return moment(value, "Do MMMM YYYY").format("Do MMM YY");
          } else {
            return moment(value).format("MMM YY");
          }
        },
      }}
      labelSkipWidth={24}
      // labelSkipHeight={12}

      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom",
          direction: "row",
          // justify: true,
          translateX: 0,
          translateY: 90,
          itemsSpacing: 20,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          // itemOpacity: 0.85,
          symbolSize: 16,
          symbolShape: "circle",
        },
      ]}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      {...props}
    />
  );
};

export const BreadCrumbs = (props) => {
  //array of objects with label and value
  // [
  //         {label: 'label', link: '/app/trades'}
  // ]

  return (
    <Breadcrumb
      mt="20px"
      mb="18px"
      spacing="8px"
      separator={
        <ChevronRight
          stroke="var(--light-gray)"
          strokeWidth={"1.5px"}
          bg="transparent"
          w="30px"
          h="30px"
          fill="transparent"
          transform={"translateY(5px)"}
        />
      }
    >
      {props.data.map((value, index) => {
        let style = "";
        if (index === 0) {
          style = styles.firstBreadcrumb;
        } else {
          style = styles.breadcrumb;
        }
        return (
          <BreadcrumbItem key={"breadcrumb" + index}>
            <BreadcrumbLink
              className={style}
              onClick={() => {
                if (value.link.length > 0) {
                  props.history.push(value.link);
                } else if (value.stockTicker.length > 0) {
                  props.history.push(`/app/trades?stock=${value.stockTicker}`);
                }
              }}
            >
              {value.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export const LineChart = (props) => {
  let { lowestValueForBaseLine, data, gradient, tooltipFormat } = props;

  let xGradient = {};
  if (gradient === true) {
    xGradient = {
      enableArea: true,
      areaOpacity: "0.1",
      defs: [
        linearGradientDef("gradientA", [
          { offset: 0, color: "inherit" },
          { offset: 85, color: "inherit", opacity: 0 },
        ]),
      ],
      fill: [{ match: "*", id: "gradientA" }],
    };
  }

  return (
    <Box height="100%" width="100%">
      <ResponsiveLine
        {...xGradient}
        theme={chartTheme}
        data={data}
        xScale={{ type: "point" }}
        curve="cardinal"
        dots={false}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        areaBaselineValue={lowestValueForBaseLine}
        label={(node) => {
          return `${currencyFormatter.format(node.value, { code: "USD" })}`;
        }}
        tooltip={(tProps) => {
          return (
            <Glass>
              <VStack
                display={"flex"}
                justifyContent="center"
                alignItems={"flex-start"}
              >
                <Text className={styles.GlassTitle}>
                  {tProps.point.data.xFormatted} | {tProps.point.serieId}
                </Text>
                <Text className={styles.GlassText}>
                  {" "}
                  {tooltipFormat(tProps.point.data.yFormatted)}
                </Text>
              </VStack>
            </Glass>
          );
        }}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={[
          "#26C363",
          "#FEB95A",
          "#2595FD",
          "#235C95",
          "#A9DFD8",
          "rgb(169, 223, 216, 0.5)",
        ]}
        markers={redZeroLine}
        labelTextColor={"var(--light-gray)"}
        labelFormat={(value) => {
          return <p>{value}</p>;
        }}
        useMesh={true}
        enableCrosshair={true}
        crosshairType="cross"
        axisTop={null}
        axisRight={null}
        axisLeft={{
          // tickSize: 0,
          format: (value) => {
            function abbreviateNumber(value) {
              var newValue = value;
              if (value >= 1000) {
                var suffixes = ["", "k", "m", "b", "t"];
                var suffixNum = Math.floor(("" + value).length / 3);
                var shortValue = "";
                for (var precision = 2; precision >= 1; precision--) {
                  shortValue = parseFloat(
                    (suffixNum != 0
                      ? value / Math.pow(1000, suffixNum)
                      : value
                    ).toPrecision(precision)
                  );
                  var dotLessShortValue = (shortValue + "").replace(
                    /[^a-zA-Z 0-9]+/g,
                    ""
                  );
                  if (dotLessShortValue.length <= 2) {
                    break;
                  }
                }
                if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
                newValue = shortValue + suffixes[suffixNum];
              }
              return newValue;
            }

            if (value >= 0) {
              return abbreviateNumber(value);
            } else if (value < 0) {
              return "-" + abbreviateNumber(Math.abs(value));
            } else {
              // return currencyFormatter.format((value), { code: 'USD' })
              return value;
            }
          },
          labelTextColor: "var(--white)",
        }}
        axisBottom={{
          // tickSize: 0,
          labelTextColor: "var(--white)",
          tickPadding: 5,
          tickRotation: 90,

          format: (value) => {
            // console.log(props.data[0].data.length)

            let display = true;

            if (props.data[0].data.length > 20) {
              props.data[0].data.map((x, index) => {
                if (value === x.x) {
                  if (index % 3 && x.x !== "Today") {
                    display = false;
                  }
                }
              });
            }

            if (display === false) {
              return "";
            } else if (value.includes("FY")) {
              return value;
            } else if (value === "Today") {
              return value;
            } else {
              return moment(value).format("DD MMM YY");
            }
          },
        }}
        labelSkipWidth={"4px"}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom",
            direction: "row",
            translateX: 0,
            translateY: 90,
            itemsSpacing: 20,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            symbolSize: 16,
            symbolShape: "circle",
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        // pointSymbol={CustomSymbol}
        pointSize={5}
        pointBorderWidth={5}
        pointBorderColor={{
          from: "color",
          modifiers: [["opacity", 0.4]],
        }}
        {...props}
      />
    </Box>
  );
};

export const Banner = ({ text, localstorageKey }) => {
  const [isOpen, setIsOpen] = useState(true);

  if (localStorage.getItem(localstorageKey) === "true" || isOpen === false) {
    return null;
  } else {
    return (
      <Alert status="info" mb="4">
        <AlertIcon />

        <Text flex="20" fontWeight={500} textAlign={"center"}>
          {text}
        </Text>

        <SecondaryButton
          color={"var(--white)"}
          onClick={() => {
            localStorage.setItem(localstorageKey, "true");
            setIsOpen(false);
          }}
        >
          Hide alert
        </SecondaryButton>
      </Alert>
    );
    // return (
    //     <Box display="flex" justifyContent={'space-between'} alignItems='center' bg={'var(--accent)'} marginBottom={'16px'} borderRadius='6px' padding="6px">
    //         <Text flex="20" fontWeight={600} cursor="pointer" onClick={() => {
    //             window.location = 'https://option-tracker.getrewardful.com/signup';
    //         }} textAlign={'center'} textDecor={"underline"}>🎉 Refer a friend and receive a 30% commission on all payments for paying customers you refer to OptionTracker.io! </Text>
    //         <CloseButton flex="1" onClick={() => {
    //             localStorage.setItem("alpha_prompt_hide_banner", "true")
    //             setIsOpen(false);
    //         }} />
    //     </Box>
    // )
  }
};

export const PromoCouponBanner = (props) => {
  let { active, discount, description, promo_code, countdown } = props.data;
  let alreadyLoggedIn = false;
  if (localStorage.access_token) {
    alreadyLoggedIn = true;
  }
  if (active !== true || alreadyLoggedIn) {
    return null;
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Text>The sale is over!</Text>;
    } else {
      // Render a countdown
      return (
        <span>
          {days} days, {hours} hours, {minutes} mins, {seconds} seconds left!
        </span>
      );
    }
  };

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 200);

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems="center"
      bg={"var(--accent)"}
      marginBottom={"20px"}
      padding="9px"
    >
      <VStack>
        <Text lineHeight="120%" flex="20" fontWeight={600} textAlign={"center"}>
          {description}{" "}
        </Text>
        <Text lineHeight="120%" flex="20" fontWeight={600} textAlign={"center"}>
          {" "}
          <Countdown
            renderer={renderer}
            date={moment(countdown, "DD/MM/YYYY hh:mm")}
          />{" "}
        </Text>
      </VStack>
    </Box>
  );
};

export const ScatterPlot = (props) => {
  return (
    <ResponsiveScatterPlot
      data={props.data}
      theme={chartTheme}
      margin={{ top: 30, right: 20, bottom: 100, left: 80 }}
      yScale={{ type: "linear", min: "auto", max: "auto" }}
      // blendMode="multiply"
      nodeSize={8}
      enableGridX={false}
      dots={false}
      // gridXValues={'date'}
      axisLeft={{
        orient: "left",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        format: (d) => `${currencyFormatter.format(d, { code: "USD" })}`,
      }}
      // tooltip={({ id, value, color }) => {
      //     return (
      //         <Glass>
      //             <VStack display={'flex'} justifyContent='center' alignItems={'flex-start'}>
      //                 <Text className={styles.GlassTitle}> {id}</Text>
      //                 <Text className={styles.GlassText}>  {currencyFormatter.format((value), { code: 'USD' })}</Text>
      //             </VStack>
      //         </Glass>
      //     )
      // }}

      legends={[]}
      markers={redZeroLine}
      layers={[
        "grid",
        "axes",
        "nodes",
        "markers",
        "mesh",
        "legends",
        "annotations",
      ]}
      colors={[
        "var(--chart-blue)",
        "var(--chart-dark-blue)",
        "var(--chart-orange)",
        "var(--chart-baby-blue)",
        "var(--chart-swamp)",
      ]}
      {...props}
    />
  );
};

export const NoDataWidget = (props) => {
  return (
    <VStack
      display={"flex"}
      justifyContent="center"
      alignItems={"center"}
      mt="20px"
    >
      <CustomToolTip
        label={
          props.label ||
          `You'll need to open & close more trades to see data here.`
        }
      >
        <HStack>
          <Notes className={styles.NoDataText}>{props.text || "No Data"}</Notes>
          <Icon className={styles.infoIcon} as={InfoFill} />
        </HStack>
      </CustomToolTip>
    </VStack>
  );
};

// Extras

export const CustomTag = (props) => {
  return (
    <Tag className={styles.tag} {...props}>
      {props.children}
    </Tag>
  );
};

// Preview

class DesignLibrary extends Component {
  constructor() {
    super();
    this.state = {
      HomeIcon: false,
      UploadIcon: false,
      TradesIcon: false,
      RoadmapIcon: false,
      MattPorfolioActive: false,
      KirstenPorfolioActive: false,
    };
  }

  render() {
    return (
      <Background>
        <Box padding={4}>
          <CustomTabsContainer>
            <CustomTabList>
              <CustomTab>Tabs</CustomTab>
              <CustomTab>Charts</CustomTab>
              <CustomTab>Colors</CustomTab>
              <CustomTab>Typography</CustomTab>
              <CustomTab>Buttons</CustomTab>
              <CustomTab>Inputs</CustomTab>
              <CustomTab>Widgets</CustomTab>
              <CustomTab>Sidebar</CustomTab>
            </CustomTabList>
            <CustomTabPanels>
              <TabPanel bg={"var(--dark-gray)"}>
                <InsetTabsContainer>
                  <InsetTabList>
                    <InsetTab>Highlights</InsetTab>
                    <InsetTab>Dividends</InsetTab>
                    <InsetTab>Valuation</InsetTab>
                    <InsetTab>Option chain</InsetTab>
                  </InsetTabList>
                  <InsetTabPanels>
                    <TabPanel>
                      <p>Test</p>
                    </TabPanel>
                    <TabPanel>
                      <p>Test 2</p>
                    </TabPanel>
                  </InsetTabPanels>
                </InsetTabsContainer>
                <InsetTabsContainer>
                  <InsetTabList tall={true}>
                    <InsetTab>Highlights</InsetTab>
                    <InsetTab>Dividends</InsetTab>
                    <InsetTab>Valuation</InsetTab>
                    <InsetTab>Option chain</InsetTab>
                  </InsetTabList>
                  <InsetTabPanels>
                    <TabPanel>
                      <p>Test</p>
                    </TabPanel>
                    <TabPanel>
                      <p>Test 2</p>
                    </TabPanel>
                  </InsetTabPanels>
                </InsetTabsContainer>
              </TabPanel>

              <TabPanel>
                <Box height={"600px"}>
                  <WidgetPanel>
                    <AccountBalance demo={true} />
                  </WidgetPanel>
                </Box>
              </TabPanel>

              <TabPanel>
                <Notes mb="2" mt="2"></Notes>
                <VStack spacing="4" alignItems="flex-start">
                  <HStack>
                    <Box className={styles.black} /> <Text>Black</Text>{" "}
                  </HStack>
                  <HStack>
                    <Box className={styles.bg} /> <Text>Background</Text>{" "}
                  </HStack>
                  <HStack>
                    <Box className={styles.darkGray} /> <Text>Dark Gray</Text>{" "}
                  </HStack>
                  <HStack>
                    <Box className={styles.gray} /> <Text>Gray</Text>{" "}
                  </HStack>
                  <HStack>
                    <Box className={styles.semiWhite} /> <Text>Semi White</Text>{" "}
                  </HStack>
                  <HStack>
                    <Box className={styles.white} /> <Text>White</Text>{" "}
                  </HStack>
                  <HStack>
                    <Box className={styles.accent} /> <Text>Accent</Text>{" "}
                  </HStack>
                  <HStack>
                    <Box className={styles.green} /> <Text>Green</Text>{" "}
                  </HStack>
                  <HStack>
                    <Box className={styles.red} /> <Text>Red</Text>{" "}
                  </HStack>
                </VStack>
              </TabPanel>

              <TabPanel>
                <Title>Title</Title>
                <ItemHeading>Heading</ItemHeading>
                <Notes>Notes</Notes>
              </TabPanel>
              <TabPanel>
                <Notes mb="2" mt="2">
                  Notes: Hover, select border missing
                </Notes>
                <VStack spacing={6} alignItems={"flex-start"}>
                  <PrimaryButton>Primary</PrimaryButton>
                  <SecondaryButton>Secondary</SecondaryButton>
                  <NegativeButton>Negative</NegativeButton>
                  <CustomIconButton activated={true} icon={<FilterIcon />}>
                    Filters
                  </CustomIconButton>
                  <CustomIconButton
                    activated={false}
                    icon={<AddWidgetStroke />}
                  >
                    Widget
                  </CustomIconButton>
                  <NotificationButton activated={false} />
                  <RoundIcon icon={<CalendarIcon />} />
                </VStack>
              </TabPanel>
              <TabPanel>
                <Notes mb="2" mt="2">
                  Notes: Hover, select, dropdown missing. Calendar, range select
                  colors.
                </Notes>
                <VStack spacing={6} alignItems={"flex-start"}>
                  <CustomInputGroup>
                    <ItemHeading>Dropdown</ItemHeading>
                    <DropDown
                      placeholder="All"
                      options={[
                        { value: "ALL", label: "All" },
                        { value: "TEST", label: "Test" },
                      ]}
                    />
                  </CustomInputGroup>

                  <CustomInputGroup>
                    <ItemHeading>Date Range Picker</ItemHeading>
                    <CustomDateRangePicker />
                  </CustomInputGroup>

                  <CustomInputGroup>
                    <ItemHeading>Text Input</ItemHeading>

                    <OverlayButton>
                      <IconInput
                        width="425px"
                        icon={<Search />}
                        placeholder="Search for stocks, ETFs & ETNs"
                        id="lookupbar"
                      />
                    </OverlayButton>
                  </CustomInputGroup>
                </VStack>
              </TabPanel>

              <TabPanel>
                <Notes mb="2" mt="2">
                  Tooltip hover missing
                </Notes>
                <VStack spacing={6} alignItems={"flex-start"}>
                  <StatWidget
                    title="Realized Profit"
                    number="10000"
                    change="43.43"
                    tooltip="Tooltip goes here..."
                  />
                  <StatWidget
                    title="Today's change"
                    number="5000"
                    change="-50"
                    tooltip="Tooltip goes here..."
                  />
                </VStack>
              </TabPanel>

              <TabPanel>
                <Notes mb="2" mt="2">
                  Guassian blur does not work in SVG on the web.
                </Notes>

                <VStack spacing={3} alignItems={"flex-start"}>
                  <SideBarProfileToggle
                    onClick={() => {
                      this.setState({
                        MattPorfolioActive: !this.state.MattPorfolioActive,
                      });
                    }}
                    isActive={this.state.MattPorfolioActive}
                    profileName="Matt's Portfolio"
                  />
                  <SideBarProfileToggle
                    onClick={() => {
                      this.setState({
                        KirstenPorfolioActive:
                          !this.state.KirstenPorfolioActive,
                      });
                    }}
                    isActive={this.state.KirstenPorfolioActive}
                    profileName="Kirsten's Portfolio"
                  />
                  <SideBarProfileToggle
                    isActive={false}
                    profileName="CUSTOM_CREATE_COMPONENT"
                  />
                </VStack>
                <Divider mt="22px" mb="22px" />
                <VStack spacing={6} alignItems={"flex-start"}>
                  <SideBarButton
                    onClick={() => {
                      this.setState({ HomeOffIcon: !this.state.HomeOffIcon });
                    }}
                    icon={
                      this.state.HomeOffIcon === false ? (
                        <HomeOffIcon />
                      ) : (
                        <HomeIcon />
                      )
                    }
                    label="Dashboard"
                  />
                  <SideBarButton
                    onClick={() => {
                      this.setState({
                        UploadOffIcon: !this.state.UploadOffIcon,
                      });
                    }}
                    icon={
                      this.state.UploadOffIcon === false ? (
                        <UploadOffIcon />
                      ) : (
                        <UploadIcon />
                      )
                    }
                    label="Upload"
                  />
                  <SideBarButton
                    onClick={() => {
                      this.setState({
                        TradesOffIcon: !this.state.TradesOffIcon,
                      });
                    }}
                    icon={
                      this.state.TradesOffIcon === false ? (
                        <TradesOffIcon />
                      ) : (
                        <TradesIcon />
                      )
                    }
                    label="Trades"
                  />
                  <SideBarButton
                    onClick={() => {
                      this.setState({
                        RoadmapOffIcon: !this.state.RoadmapOffIcon,
                      });
                    }}
                    icon={
                      this.state.RoadmapOffIcon === false ? (
                        <RoadmapOffIcon />
                      ) : (
                        <RoadmapIcon />
                      )
                    }
                    label="Roadmap"
                  />
                </VStack>
              </TabPanel>
            </CustomTabPanels>
          </CustomTabsContainer>
        </Box>
      </Background>
    );
  }
}

const mapStateToProps = (state) => {
  const { settings } = state;
  return { settings };
};

export default connect(mapStateToProps)(DesignLibrary);

//Examples
//Modal
