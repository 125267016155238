import {
  Box,
  Center,
  Divider,
  HStack,
  Image,
  Link,
  Text,
  useColorMode,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import * as React from "react";
import styles from "./Footer.module.css";
import { footerLinks } from "./_data";

const App = (props: any) => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Box as="footer" id="footer" className={styles.footer}>
        <Divider marginBottom="12px" />

        <Box
          width="100%"
          display="flex"
          justifyContent={"space-between"}
          pb="12px"
          pl="20px"
          pr="20px"
        >
          <Image
            src="/images/reddot/red-dot-logo.svg"
            alt="Option Tracker"
            width="230px"
            pr="10px"
          />
          <Wrap id="bottom" alignItems={"center"} display={"flex"}>
            {footerLinks.map((link, idx) => (
              <WrapItem key={idx}>
                <Box
                  pr="10px"
                  as="a"
                  fontSize="14px"
                  color="var(--white)"
                  fontWeight="400"
                  href={link.href}
                >
                  {link.label}
                </Box>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      </Box>
      <Box
        as="footer"
        id="footer"
        className={styles.footer}
        backgroundColor={"var(--bg)"}
        w="100%"
      >
        <Divider marginBottom="12px" />
        <HStack spacing={"16px"} pb="16px" wrap={"wrap"}>
          <Text color="var(--gray)">© Option Tracker Ltd</Text>
          <Text color="var(--gray)" fontSize={"6"}>
            UI/UX design by{" "}
            <Link href="https://spaceberrystudio.com/optr">
              Spaceberry Studio
            </Link>
          </Text>
        </HStack>
      </Box>
    </>
  );
};

export default App;
